import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import root from '../../../design/styles.module.scss';
import { Breadcrumbs } from '../../../../common/Breadcrumbs';
import { t } from '../../../../../i18n';

interface IProps {
  title: string;
}

const InternshipTitle: FC<IProps> = ({ title }) => (
  <div className={classNames(styles.wrapper)}>
    <div className={root.breadcrumbs}>
      <div className={styles.breadcrumbs}>
        <Breadcrumbs title={title} />
      </div>
    </div>
    <div className={styles.titleContainer}>
      <h3 className={styles.title}>{t('cases.internship.title.title')}</h3>
      <p className={styles.subtitle}>{t('cases.internship.title.desc')}</p>
    </div>
    <div className={styles.imageContainer}>
      <div className={styles.imageContainer__mainImageWrapper}>
        <img
          src="/case/internship/main.png"
          alt=""
          className={styles.mainImage}
        />
      </div>
      <div className={styles.imageContainer__overlayImageWrapper}>
        <img
          src="/case/internship/overlay.png"
          alt=""
          className={styles.overlayImage}
        />
      </div>
    </div>
  </div>
);

export { InternshipTitle };
