import React, { FC, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

interface SpecialProps {
  prefix: string;
  index: number;
}

interface AccordionProps {
  desc: string;
  prefix: string;
  points: number[];
  isOpen: boolean;
}

const Special: FC<SpecialProps> = ({ prefix, index }) => {
  const image = index === 0 ? 'credit-card' : 'rocket';
  return (
    <div className={styles.accordion__specialPoint}>
      <div className={styles.accordion__specialPointWrapper}>
        <img
          src={`/case/internship/special/${image}.svg`}
          alt=""
          className={styles.accordion__specialPointImage}
        />
        <div className={styles.accordion__specialTextWrapper}>
          <div className={styles.accordion__specialTitle}>
            {t(`${prefix}.title`)}
          </div>
          <div className={styles.accordion__specialSubtitle}>
            {t(`${prefix}.subtitle`)}
          </div>
        </div>
      </div>
    </div>
  );
};

const Accordion: FC<AccordionProps> = ({ desc, prefix, points, isOpen }) => {
  const [open, setOpen] = useState(isOpen);
  const button = open ? 'open' : 'close';
  const specials = ['One', 'Two'];
  return (
    <div className={styles.accordion}>
      <div className={styles.accordion__titleContainer}>
        <div className={styles.accordion__title}>{t(`${prefix}.title`)}</div>
        <button
          className={styles.accordion__button}
          onClick={() => setOpen(!open)}
          type="button"
        >
          <img src={`/case/internship/buttons/${button}.svg`} alt="" />
        </button>
      </div>
      <div
        className={open ? styles.accordion__contentContainer : styles.hidden}
      >
        {desc && desc.length > 0 && (
          <div className={styles.accordion__desc}>{t(desc)}</div>
        )}
        <div className={styles.accordion__subtitle}>
          {t(`${prefix}.subtitle`)}
        </div>
        {points.map((point) => (
          <div
            className={styles.accordion__point}
            key={`${prefix}-${point + 1}`}
          >
            {t(`${prefix}.${point}`)}
          </div>
        ))}
        <div className={styles.accordion__specialContainer}>
          {specials.map((item, index) => (
            <Special
              prefix={`${prefix}.special${item}`}
              index={index}
              key={`$specialPrefix-${item}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const InternshipOptions: FC = () => {
  const accordions = [
    { points: [0, 1, 2, 3], desc: false, isOpen: false },
    { points: [0, 1, 2, 3, 4, 5, 6], desc: false, isOpen: false },
  ];

  // GATSBY_INTERNSHIP_SWITCH = 1
  // 1 — Все материалы отображаются
  // 0 — Отключены материалы корпоративного университета IceRock

  const internshipSwitch = Number(process.env.GATSBY_INTERNSHIP_SWITCH);

  if (internshipSwitch) {
    accordions.push({ points: [0, 1, 2, 3], desc: true, isOpen: false });
  }

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={styles.title}>
        {internshipSwitch
          ? t('cases.internship.options.title')
          : '2 варианта стажировки'}
      </div>
      <div className={styles.subtitle}>
        <div className={styles.subtitle__text}>
          {t('cases.internship.options.subtitle')}
        </div>
      </div>
      {accordions.map((accordion, i) => (
        <Accordion
          key={`accordion${i + 1}`}
          desc={accordion.desc ? `cases.internship.options.${i}.desc` : ''}
          prefix={`cases.internship.options.${i}`}
          points={accordion.points}
          isOpen={accordion.isOpen}
        />
      ))}
    </div>
  );
};

export { InternshipOptions };
