import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

interface BenefitProps {
  text: string;
}

const Benefit: FC<BenefitProps> = ({ text }) => (
  <div className={styles.benefit}>{t(text)}</div>
);

const InternshipBenefits: FC = () => {
  const benefits = [0, 1, 2, 3, 4, 5, 6, 7];
  return (
    <div className={classNames(styles.wrapper)}>
      <div className={styles.title}>{t('cases.internship.benefits.title')}</div>
      {benefits.map((benefit) => (
        <Benefit
          key={`benefit-${benefit + 1}`}
          text={`cases.internship.benefits.${benefit}`}
        />
      ))}
    </div>
  );
};

export { InternshipBenefits };
