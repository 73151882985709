import React, { FC, useMemo } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MainLayout } from '../../templates/MainLayout';
import { IDirectionTagsQuery } from '../../queries/directionTags/types';
import { ICaseQuery } from '../../queries/cases/types';
import { InternshipTitle } from '../../components/directions/internship/screens/InternshipTitle';
import { InternshipFeatures } from '../../components/directions/internship/screens/InternshipFeatures';
import { InternshipWhatYouGet } from '../../components/directions/internship/screens/InternshipWhatYouGet';
import { InternshipOptions } from '../../components/directions/internship/screens/InternshipOptions';
import { InternshipBenefits } from '../../components/directions/internship/screens/InternshipBenefits';
import { InternshipForm } from '../../components/directions/internship/screens/InternshipForm';
import { InternshipCases } from '../../components/directions/internship/screens/InternshipCases';
import { ReCaptchaProvider } from '../../components/common/ReCaptchaProvider';

type IProps = PageProps<
  IDirectionTagsQuery & ICaseQuery,
  { locale: string; tag: string; page: number }
>;

const InternshipPage: FC<IProps> = ({
  data,
  location,
  pageContext: { locale = 'ru', tag = '', page = 0 },
}) => {
  const direction = data.directionTags.edges.find((edge) =>
    location.pathname.includes(edge.node.frontmatter.url)
  );

  const projects = useMemo(
    () =>
      data.projects.edges
        .map((edge) => edge.node)
        .filter((node) => {
          const { platforms } = node.frontmatter.previewImage;
          return (
            (!tag ||
              (node.frontmatter.tags && node.frontmatter.tags.includes(tag))) &&
            node.frontmatter.status !== 'inDevelop' &&
            node.frontmatter.status !== 'inRevision' &&
            (platforms.includes('ios') || platforms.includes('android'))
          );
        }),
    [data.projects, tag]
  );

  return (
    <ReCaptchaProvider>
      <MainLayout
        locale={locale}
        title={direction?.node.frontmatter.name ?? ''}
      >
        <InternshipTitle title={direction?.node.frontmatter.name ?? ''} />
        <InternshipFeatures />
        <InternshipCases projects={projects} locale={locale} />
        <InternshipWhatYouGet />
        <InternshipOptions />
        <InternshipBenefits />
        <InternshipForm />
      </MainLayout>
    </ReCaptchaProvider>
  );
};

export const query = graphql`
  query {
    directionTags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/directions/tags/" } }
    ) {
      ...DirectionTagsFields
    }

    projects: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/cases/projects/" } }
      sort: { fields: [frontmatter___status, frontmatter___title], order: ASC }
    ) {
      ...CasePreviews
    }
  }
`;

export default InternshipPage;
