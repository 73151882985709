import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

interface FeatureProps {
  imgSource: string;
  text: string;
}

const Feature: FC<FeatureProps> = ({ imgSource, text }) => (
  <div className={styles.feature}>
    <div className={styles.feature__image}>
      <img src={imgSource} alt="" />
    </div>
    <div className={styles.feature__text}>{t(text)}</div>
  </div>
);

const InternshipFeatures: FC = () => {
  const featureImages = [
    '/case/internship/features/inventory.svg',
    '/case/internship/features/world.svg',
    '/case/internship/features/share.svg',
  ];
  return (
    <div className={classNames(styles.wrapper)}>
      <div className={styles.featuresContainer}>
        {featureImages.map((img, i) => (
          <Feature
            key={`feature-${i + 1}`}
            imgSource={img}
            text={`cases.internship.features.${i}`}
          />
        ))}
      </div>
    </div>
  );
};

export { InternshipFeatures };
