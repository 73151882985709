import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';
import { sendInternshipRequest } from '../../../../../utils/api';
import { OopsPanel } from '../../../../common/OopsPanel';
import { InternshipSuccessModal } from '../../components/InternshipSuccessModal';
import { InternshipTextInput } from '../../components/InternshipTextInput';
import { Textarea } from '../../../../inputs/Textarea';
import { Button } from '../../../../main/Button';

const InternshipForm: FC = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formName = 'order_form_internship';

  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [trainingName, setTrainingName] = useState('default');
  const [programmingExperience, setProgrammingExperience] = useState('');
  const [disabled, setDisabled] = useState(false);

  const options = [
    { value: 'fromZero', label: 'стажировка с нуля' },
    { value: 'beforeDiploma', label: 'преддипломная практика' },
    { value: 'corporateUniversity', label: 'корпоративный университет' },
  ];

  const validateFields = useCallback(() => {
    const errorsField = {
      name: !name || name.length <= 1,
      programmingExperience:
        !programmingExperience || programmingExperience.length <= 1,
      email: !email || email.length <= 2,
      trainingName: trainingName === 'default',
    };

    setErrors(errorsField);
    return !Object.values(errorsField).some((val) => val);
  }, [name, email, trainingName, programmingExperience, setErrors]);

  const [success, setSuccess] = useState(false);
  const [showOops, setShowOops] = useState(false);

  const clearFields = useCallback(() => {
    setEmail('');
    setName('');
    setTrainingName('default');
    setProgrammingExperience('');
  }, [
    setName,
    setEmail,
    setTrainingName,
    setTrainingName,
    setProgrammingExperience,
  ]);

  const removeError = (
    fieldName: string,
    prevValue: string,
    curValue: string
  ) => {
    if (prevValue !== curValue) {
      const errorsTemp = { ...errors, [fieldName]: false };
      setErrors(errorsTemp);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const nameInput = e.target.name;
    const { value } = e.target;
    if (errors[name])
      setErrors((prevState) => ({ ...prevState, [nameInput]: false }));

    setProgrammingExperience((prevState) => value);
  };

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setDisabled(true);

      try {
        if (!validateFields()) return;
        if (!executeRecaptcha) return;
        const captcha = await executeRecaptcha(formName);

        const res = await sendInternshipRequest({
          name,
          email,
          trainingName,
          captcha,
          programmingExperience,
        });

        if (res) {
          setSuccess(true);
          clearFields();
        }
      } catch (e) {
        setShowOops(true);
      } finally {
        setDisabled(false);
      }
    },
    [
      name,
      email,
      trainingName,
      validateFields,
      setTrainingName,
      programmingExperience,
    ]
  );

  useEffect(() => {
    if (showOops) {
      setShowOops(false);
    }
  }, [name, email, trainingName]);

  const closeSuccessModal = useCallback(() => setSuccess(false), []);

  // GATSBY_INTERNSHIP_SWITCH = 1
  // 1 — Все материалы отображаются
  // 0 — Отключены материалы корпоративного университета IceRock

  const internshipSwitch = Number(process.env.GATSBY_INTERNSHIP_SWITCH);

  return (
    <>
      <OopsPanel isOpen={showOops} />
      <form
        className={classnames('content', styles.wrap)}
        onSubmit={onSubmit}
        id={formName}
      >
        {success && <InternshipSuccessModal onClose={closeSuccessModal} />}
        <div className={styles.title}>{t('cases.internship.form.title')}</div>

        <div className={styles.inputs}>
          <InternshipTextInput
            value={name}
            name="name"
            handler={setName}
            label="cases.internship.form.name"
            error={errors.name ? 'form.field_required' : ''}
            icon="/case/internship/form/name.svg"
            removeError={removeError}
          />

          <InternshipTextInput
            value={email}
            name="email"
            handler={setEmail}
            label="cases.internship.form.email"
            error={errors.email ? 'form.field_required' : ''}
            icon="/case/internship/form/email.svg"
            removeError={removeError}
          />
        </div>

        <div className={styles.selectWrapper}>
          <select
            value={trainingName}
            onChange={(e) => {
              setTrainingName(e.target.value);
              if (e.target.value !== 'default') {
                const errorsTemp = { ...errors, trainingName: false };
                setErrors(errorsTemp);
              }
            }}
            className={`
            ${styles.select}
            ${trainingName === 'default' ? styles.select__placeholder : ''}
            ${errors.trainingName ? styles.select__error : ''}
           `}
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundImage: "url('/case/internship/buttons/close.svg')",
              backgroundColor: '#F4F3F8',
              backgroundPosition: '98.5% 50%',
              WebkitAppearance: 'none',
              MozAppearance: 'none',
            }}
          >
            <option value="default" disabled hidden>
              Выберите стажировку
            </option>
            {internshipSwitch
              ? options.map((optionLocal) => (
                  <option key={optionLocal.label} value={optionLocal.value}>
                    {optionLocal.label}
                  </option>
                ))
              : options.slice(0, 2).map((optionLocal) => (
                  <option key={optionLocal.label} value={optionLocal.value}>
                    {optionLocal.label}
                  </option>
                ))}
          </select>
          {errors.trainingName && (
            <div className={styles.error}>{t('contacts.fill_this_field')}</div>
          )}

          <div className={styles.textarea}>
            <Textarea
              name="programmingExperience"
              value={programmingExperience ?? ''}
              handler={onChange}
              placeholder="cases.internship.form.textarea"
              error={errors.programmingExperience ? 'form.field_required' : ''}
              removeError={removeError}
            />
          </div>
        </div>

        <div className={styles.submit}>
          <Button
            className={styles.button}
            isLoading={disabled}
            disabled={disabled}
          >
            {t('cases.internship.form.button')}
          </Button>
        </div>
      </form>
    </>
  );
};

export { InternshipForm };
