import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

interface CardProps {
  imgSource: string;
  text: string;
}

const Card: FC<CardProps> = ({ imgSource, text }) => (
  <div className={styles.card}>
    <div className={styles.card__image}>
      <img src={imgSource} alt="" />
    </div>
    <div className={styles.card__text}>{t(text)}</div>
  </div>
);

const InternshipWhatYouGet: FC = () => {
  const cardsImages = [
    '/case/internship/what-you-get/suitcase.svg',
    '/case/internship/what-you-get/cap.svg',
    '/case/internship/what-you-get/weight.svg',
    '/case/internship/what-you-get/checklist.svg',
    '/case/internship/what-you-get/cup.svg',
    '/case/internship/what-you-get/dollar.svg',
  ];

  // GATSBY_INTERNSHIP_SWITCH = 1
  // 1 — Все материалы отображаются
  // 0 — Отключены материалы корпоративного университета IceRock

  const internshipSwitch = Number(process.env.GATSBY_INTERNSHIP_SWITCH);

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={styles.title}>
        {t('cases.internship.whatYouGet.title')}
      </div>
      <div className={styles.cardsContainer}>
        {internshipSwitch
          ? cardsImages.map((img, i) => (
              <Card
                key={`card-image-${i + 1}`}
                imgSource={img}
                text={`cases.internship.whatYouGet.${i}`}
              />
            ))
          : cardsImages
              .slice(0, 5)
              .map((img, i) => (
                <Card
                  key={`card-image-${i + 1}`}
                  imgSource={img}
                  text={`cases.internship.whatYouGetWithoutCorpEdu.${i}`}
                />
              ))}
      </div>
    </div>
  );
};

export { InternshipWhatYouGet };
