import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';
import { ICaseQueryEdgeInternship } from '../../../../../queries/cases/types';
import { IconComponent } from '../../../../common/Icon';
import { AndroidIcon } from '../../../../icons/AndroidIcon';
import { AppleIcon } from '../../../../icons/AppleIcon';
import { WebIcon } from '../../../../icons/WebIcon';
import { changePath } from '../../../../../utils/hooks';

export type IData = {
  projects: ICaseQueryEdgeInternship[];
  locale: string;
};

const platformIcons: Record<string, FC<any>> = {
  android: AndroidIcon,
  ios: AppleIcon,
  web: WebIcon,
};

interface CardProps {
  title: string;
  key: string;
  year: number;
  platforms: string[];
  background: string;
  imgSource: string;
  platformName: string;
  link: string;
}

const Card: FC<CardProps> = ({
  title,
  year,
  platforms,
  background,
  imgSource,
  platformName,
  link,
}) => {
  const platformNameLocal = platformName.toLowerCase();
  return (
    <a href={link} className={styles.cardLink}>
      <div className={styles.card} style={{ backgroundColor: background }}>
        <div className={styles.card__titleContainer}>
          <div className={styles.card__textWrapper}>
            <div className={styles.card__title}>{t(title)}</div>
            <div className={styles.card__subtitle}>{t(String(year))}</div>
          </div>
          <div className={styles.card__imageWrapper}>
            {platforms.map((platform) => (
              <span>
                <IconComponent
                  name={platform}
                  icons={platformIcons}
                  iconProps={{ color: '#FFFFFF', width: 17, height: 17 }}
                />
              </span>
            ))}
          </div>
        </div>
        <div className={styles.card__contentWrapper}>
          <img
            src={imgSource}
            alt="app screenshot"
            className={
              platformNameLocal === 'ios'
                ? styles.card__contentIos
                : styles.card__contentAndroid
            }
          />
          <img
            src={
              platformNameLocal === 'ios'
                ? '/devices/iphone-portrait.png'
                : '/devices/android-portrait.png'
            }
            alt="device frame"
            className={styles.card__imageFrame}
          />
        </div>
      </div>
    </a>
  );
};

const InternshipCases: FC<IData> = ({ projects, locale }) => {
  const activeCases = ['snow4u', 'easy2ride', 'picker-app'];

  const getSlug = (project: ICaseQueryEdgeInternship) => {
    const slugSplit = changePath(project.fileAbsolutePath)
      .toLowerCase()
      .split('/');

    return slugSplit[slugSplit.length - 1];
  };

  const activeProjects = projects.filter((projectLocal) =>
    activeCases.includes(getSlug(projectLocal))
  );

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{t('cases.internship.cases.title')}</div>
      </div>
      <div className={styles.subtitleContainer}>
        <div className={styles.subtitle}>
          {t('cases.internship.cases.subtitle')}
        </div>
        <a className={styles.link} href="/cases/">
          {t('cases.internship.cases.link')}
        </a>
      </div>

      <div className={styles.mobileNavContainer}>
        <a className={styles.mobileLink} href="/cases/">
          {t('cases.internship.cases.link')}
        </a>
      </div>

      <div className={styles.cardsContainer}>
        {activeProjects &&
          activeProjects.length > 0 &&
          activeProjects.map((project) => (
            <Card
              key={project.id}
              title={project.frontmatter.previewImage.title}
              background={project.frontmatter.previewImage.background}
              imgSource={project.frontmatter.result?.screens[0][0][1] || ''}
              year={project.frontmatter.previewImage.year}
              platforms={project.frontmatter.previewImage.platforms}
              platformName={
                project.frontmatter.result?.platforms[0][0] || 'ios'
              }
              link={changePath(project.fileAbsolutePath, '/cases/projects/')}
            />
          ))}
      </div>

      <div className={styles.cardsContainerMobile}>
        {activeProjects && activeProjects.length > 0 && (
          <Card
            key={activeProjects[0].id}
            title={activeProjects[0].frontmatter.previewImage.title}
            background={activeProjects[0].frontmatter.previewImage.background}
            imgSource={
              activeProjects[0].frontmatter.result?.screens[0][0][1] || ''
            }
            year={activeProjects[0].frontmatter.previewImage.year}
            platforms={activeProjects[0].frontmatter.previewImage.platforms}
            platformName={
              activeProjects[0].frontmatter.result?.platforms[0][0] || 'ios'
            }
            link={
              (changePath(activeProjects[0].fileAbsolutePath),
              '/cases/projects/')
            }
          />
        )}
      </div>
    </div>
  );
};

export { InternshipCases };
